import { URL_MATCHES } from "@api/doinsport/services/events/index";
import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const getMatches = (itemsPerPage = 5, page = 1, fromDate= null, untilDate = null ) => {
  const params = fromDate === null || untilDate === null ? '' : '&startAt[after]=' + fromDate + '&startAt[before]=' + untilDate ;

  return _get(
    URL_MATCHES +
    '?club.id=' + clubId +
    '&itemsPerPage=' + itemsPerPage +
    '&page=' + page + params
  );
}


export const getMatch = (id) => _get(URL_MATCHES + '/' + id);

export const postMatch = (match) => _post(URL_MATCHES, match);

export const putMatch = (match) => _put(URL_MATCHES + '/' + match.id, match);

export const deleteMatch = (id) => _put(URL_MATCHES + '/' + id, {canceled: true});
