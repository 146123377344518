<template>
  <div>
    <div v-if="booking.match === null && booking.activityType === 'sport'">
      <d-button
        :class="innerWidth ? 'ml-4' : 'ml-0 mr-0'"
        class="font-text-title match-link"
        text="views.bookings.index.dropdown.create-match"
        @on:button-click="newMatch"
      />
    </div>
    <div
      v-else
      v-show="booking.activityType === 'sport'"
    >
      <b-dropdown
        id="match-booking"
        variant="link"
        class="drop-section"
        no-caret
        menu-class="w-100"
        toggle-class="text-decoration-none"
      >
        <template #button-content>
          <span class="match-link">
            {{ $t('views.bookings.index.dropdown.available-match') }}
            <img
              :src="require('@/assets/icons/dots.svg')"
              class="ml-1"
              width="26px"
            />
          </span>
        </template>
        <b-dropdown-item
          @click="$router.push({name: 'event', params: {id: booking.match.id}})"
        >
          {{ $t('views.bookings.index.dropdown.show-match') }}
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item
          @click="editMatch"
        >
          {{ $t('views.bookings.index.dropdown.edit-match') }}
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item
          @click="modal.display = !modal.display"
        >
          {{ $t('views.bookings.index.dropdown.delete-match') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <booking-match-modal
      :key="`match-modal-${key}`"
      :mode="mode"
      :booking="booking"
      :show-match="showMatch"
      class="custom-sub-modal"
      @hidden="showMatch = false"
      @on:delete="modal.display = !modal.display"
    />
    <confirm-modal
      :data="modal"
      @on:match:confirm="deleteMatch"
      @on:match:cancel="modal.hide = !modal.hide"
    />
  </div>
</template>
<script>
import {deleteMatch, getMatch} from "@api/doinsport/services/events/events.api";

export default {
  data: () => ({
    showMatch: false,
    mode: 'create',
    key: 0,
    modal: {
      hide: false,
      display: false,
      target: 'match',
      text: 'views.bookings.index.match-modal.modal.text',
      title: 'views.bookings.index.match-modal.modal.title',
    },
  }),
  props: ['innerWidth', 'isBusy', 'booking'],
  components: {
    ConfirmModal: () => import("@views/planning/schedule/playgrounds/bookings/details/footer-actions/ConfirmModal"),
    BookingMatchModal: () => import("@custom/match/BookingMatchModal"),
  },
  methods: {
    newMatch() {
      this.mode = 'create';
      this.displayMatchModal();
    },
    editMatch() {
      getMatch(this.booking.match.id)
        .then((response) => {
          this.booking.match = response.data;
          this.mode = 'update';
          this.displayMatchModal();
        })
      ;
    },
    deleteMatch() {
      deleteMatch(this.booking.match.id)
        .then(() => {
          this.booking.match = null;
          this.modal.hide = !this.modal.hide;
          this.showMatch = false;
        })
      ;
    },
    displayMatchModal() {
      this.key++;

      this.$nextTick(() => {
        this.showMatch = true;
      });
    }
  }
}
</script>
<style scoped lang="scss" src="@lazy/planning/_match-actions.scss"/>
